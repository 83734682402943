
import Vue from 'vue';
import marked from 'marked';
import highlight from 'highlight.js';

// import 'github-markdown-css/github-markdown.css';
import '@/assets/css/sspai.less';
import 'highlight.js/styles/monokai-sublime.css';

marked.setOptions({
  renderer: new marked.Renderer(),
  gfm: true,
  breaks: true,
  headerPrefix: 'header-nav',
  highlight(code) {
    return highlight.highlightAuto(code).value;
  },
});

export default Vue.extend({
  name: 'MdPreview',
  directives: {
    // TODO: Element HTML Element
    highlight(el: any) {
      const blocks = el.querySelectorAll('pre code');
      blocks.forEach(block => {
        highlight.highlightBlock(block);
      });
    },
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    padding: {
      type: [String, Number],
      default: '10px',
    },
  },
  computed: {
    articleHtml() {
      const renderer = new marked.Renderer();
      let index = 0;
      renderer.heading = function(text, level) {
        return `<h${level} id="titleAnchor-${index++}">${text}</h${level}>`;
      };
      let content = marked(this.content || '', { renderer });
      const path = process.env.baseUrl;
      const imgreg = /<img.*?>/gi;
      content = content.replace(imgreg, function(imgsrc) {
        if (!imgsrc.includes('https://') && !imgsrc.includes('http://')) {
          imgsrc = imgsrc.replace(/src=(?:"\s*([^"]*)\s*"|'\s*([^']*)\s*'|(\S+))/i, 'src="' + path + '$1"');
        }
        return imgsrc;
      });
      return content;
    },
    styles() {
      return {
        padding: typeof this.padding === 'number' ? `${this.padding}px` : this.padding,
      };
    },
  },
});
