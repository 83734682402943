import { render, staticRenderFns } from "./md-editor.vue?vue&type=template&id=5bf27f0e&scoped=true"
import script from "./md-editor.ts?vue&type=script&lang=js&external"
export * from "./md-editor.ts?vue&type=script&lang=js&external"
import style0 from "./md-editor.less?vue&type=style&index=0&id=5bf27f0e&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bf27f0e",
  null
  
)

export default component.exports