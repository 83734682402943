import Vue from 'vue';
import Vuex from 'vuex';

import ZSelect from '@/components/base/z-select/';
import Btn from '@/components/base/btn/';
import Upload from '@/components/base/upload/';
import MdEditor from '@/components/kit/md-editor/';
import ZSwitch from '@/components/base/z-switch/';
import Modal from '@/components/base/modal/';

import { MyLocalStorage } from '@/utils/storage';

import { handleCompress, dataURI2Blob } from '@/assets/js/img-healper';

const { mapGetters, mapActions } = Vuex;

interface TFormData {
  title?: string;
  category_id?: Array<string>;
  tag_id?: string;
  poster?: string;
  content?: string;
  status?: boolean;
}

interface TuploadParams {
  usedFor: string;
}

interface TData {
  isPostBlogLoading: boolean;
  isBlogDetailLoading: boolean;
  isShowConfirmModal: boolean;
  formData: TFormData;
  isTagLoading: boolean;
  articleId: string;
  uploadParams: TuploadParams;
  uploadParamsForWord: TuploadParams;
  markdownOption: any;
}

export default Vue.extend({
  name: 'AdminWrite',
  components: {
    ZSelect,
    Btn,
    Upload,
    MdEditor,
    ZSwitch,
    Modal,
  },
  data(): TData {
    return {
      isPostBlogLoading: false,
      isBlogDetailLoading: false,
      isShowConfirmModal: false,
      formData: {
        poster: '',
        status: true,
      },
      isTagLoading: false,
      articleId: this?.$route?.query?.articleId?.toString() || '',
      uploadParams: { usedFor: 'poster' },
      uploadParamsForWord: { usedFor: 'wordmd' },
      markdownOption: {
        header: true, // 标题
        bold: true, // 粗体
        italic: true, // 斜体
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        // readmodel: true, // 沉浸式阅读
        // htmlcode: true, // 展示html源码
        help: true, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        // trash: true, // 清空
        save: true, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        // alignleft: true, // 左对齐
        // aligncenter: true, // 居中
        // alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true, // 预览
      },
    };
  },
  computed: {
    ...mapGetters('common', {
      categoryList: 'getCategoryList',
      tagList: 'getTagList',
      cacheArticleData: 'getCacheArticleData',
    }),
  },
  mounted() {
    const userInfo = MyLocalStorage.get('userInfo');
    if (userInfo) {
      this.userInfo = userInfo;
    }

    if (!this.tagList || !this.tagList.length) {
      this.$store.dispatch('common/requestTagList');
    }
    if (!this.categoryList || !this.categoryList.length) {
      this.$store.dispatch('common/requestCategoryList');
    }
    if (this.articleId) {
      this.requestBlogDetail();
    }

    if (this.cacheArticleData) {
      this.formData = Object.assign({}, this.formData, this.cacheArticleData);
    }
  },
  beforeDestroy() {
    const cacheData = {
      ...this.formData,
    };

    this.$store.dispatch('common/cacheArticleData', { ...cacheData });
  },
  methods: {
    ...mapActions({
      toggleSignInModal: 'common/toggleSignInModal',
    }),

    /**
     * @desc 请求 博客详情 编辑
     */
    requestBlogDetail() {
      const params = {
        id: this.articleId,
      };
      this.isBlogDetailLoading = true;
      this.$myApi.articles
        .show(params)
        .then((res: any) => {
          this.isBlogDetailLoading = false;
          this.handleRecoveryBlogDetail(res.data);
        })
        .catch(() => {
          this.isBlogDetailLoading = false;
        });
    },

    /**
     * @desc 编辑的时候 博客详情回显
     */
    handleRecoveryBlogDetail(data: any) {
      const { title, category_id, tags, poster, content, status } = data;

      this.formData = {
        title,
        category_id: [category_id],
        tag_id: tags.map(tag => tag.id),
        poster,
        content,
        status,
      };
    },

    async handleUpload(pos, file) {
      let compressFileBase64 = null;
      let compressFileBlob = file;

      if (!file.name.includes('.gif')) {
        compressFileBase64 = await handleCompress(file);
        compressFileBlob = dataURI2Blob(compressFileBase64);
      }

      const formdata = new FormData();
      formdata.append('file', compressFileBlob);
      formdata.append('fileItemName', file.name);
      formdata.append('usedFor', 'poster');

      this.$myApi.upload
        .createFormData(formdata)
        .then(res => {
          this.$refs.mdeditor.$img2Url(pos, res?.data?.path);
        })
        .catch(err => {
          console.log(err, 'err2');
        });
    },

    /**
     * @desc 上传 格式出错
     */
    handleFormatError(file: any) {
      this.$toast.error(`文件 ${file.name} 格式不对`);
    },

    /**
     * @desc 上传 大小限制
     */
    handleMaxSize(file: any) {
      this.$toast.error(`文件 ${file.name} 太大, 不可超过2M`);
    },

    /**
     * @desc 上传 成功
     */
    handleUploadSuccess(res: any) {
      this.formData.poster = res.data.path;
    },

    /**
     * @desc word 上传 成功
     */
    handleUploadSuccessWord(res) {
      this.formData.appendFile = res.data.path;
      this.formData.content = res.data.mdStr;
    },

    /**
     * @desc 检查表单填写是否合格
     */
    checkIsReadyPost() {
      const { title, category_id, tag_id, content } = this.formData;
      console.log(this.formData, 'this.formData');
      if (!this.userInfo) {
        this.$toast.info('请登录');
        this.toggleSignInModal(true);
      } else if (!title) {
        this.$toast.error('请填写文章标题');
      } else if (!category_id) {
        this.$toast.error('请选择文章分类');
      } else if (!tag_id) {
        this.$toast.error('请选择文章标签');
      }
      // else if (!poster) {
      //   this.$toast.error('请选择文章海报');
      // }
      else if (!content) {
        this.$toast.error('请填写文章内容');
      } else {
        return true;
      }
      return false;
    },

    handleConfirmSubmit() {
      this.handleShowConfirmModal();
    },

    /**
     * @desc 提交
     */
    handleSubmit() {
      if (!this.checkIsReadyPost()) {
        this.handleHideConfirmModal();
        return;
      }
      if (this.articleId) {
        this.requestEditArticle();
      } else {
        this.requestArticle();
      }
    },

    /**
     * @desc 请求 发布文章
     */
    requestArticle() {
      const params = {
        ...this.formData,
        user_id: this.userInfo.id,
      };
      this.isPostBlogLoading = true;
      this.$myApi.articles
        .create(params)
        .then((res: any) => {
          this.isPostBlogLoading = false;
          this.$router.push({ path: `/admin/article` });
        })
        .catch(() => {
          this.isPostBlogLoading = false;
        });
    },

    /**
     * @desc 请求 修改文章
     */
    requestEditArticle() {
      const params = {
        ...this.formData,
        id: this.articleId,
        // blogId: this.articleId,
        user_id: this.userInfo.id,
      };
      this.isPostBlogLoading = true;
      this.$myApi.articles
        .update(params)
        .then((res: any) => {
          this.isPostBlogLoading = false;
          this.$router.push({ path: `/admin/article` });
        })
        .catch(() => {
          this.isPostBlogLoading = false;
        });
    },

    handleShowConfirmModal() {
      this.isShowConfirmModal = true;
    },

    handleHideConfirmModal() {
      this.isShowConfirmModal = false;
    },
  },
});
