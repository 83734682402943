
import Vue from 'vue';

export default Vue.extend({
  name: 'Modal',
  props: {
    width: {
      type: [Number, String],
      default: '300px',
    },
    closeOnClickModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalContainerStyle() {
      return {
        width: typeof this.width === 'number' ? `${this.width}px` : this.width,
      };
    },
  },
  methods: {
    handleClickModal() {
      if (this.closeOnClickModal) {
        this.$emit('close');
      }
    },
  },
});
